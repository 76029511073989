import { Button, ButtonProps, Drawer, Flex, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  Active,
  DndContext,
  Over,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { arrayMove } from '@dnd-kit/sortable'
import {
  TableColumnIcon,
  Space,
  CloseOutlined,
  TextThirdGen,
  CircleExclamation,
  TitleThirdGen,
} from '@signifyd/components'
import { FC, useState } from 'react'
import Icon from '@ant-design/icons'
import { isEqual } from 'lodash'
import { useQueryClient } from '@tanstack/react-query'
import useGetUserData from 'core/hooks/useGetUserData'
import { colorSlate } from '@signifyd/colors'
import { spacingMD, spacingSM } from '@signifyd/ant'
import { colorGold } from '@signifyd/colors'
import { Columns } from '../useGetSavedColumns'
import useUpdateUiState from '../../NavigationWrapper/useUpdateUiState'
import { ColumnContextMapKeys } from '../../../containers/SearchResultsTable/ColumnConfig.utils'
import ThirdGenColumnDrawerFooter from './ThirdGenColumnDrawerFooter'
import ThirdGenLockedItems from './components/ThirdGenLockedItems/ThirdGenLockedItems'
import ThirdGenDynamicItems from './components/DynamicItems/ThirdGenDynamicItems'
import ThirdGenHiddenColumnsEmpty from './components/ThirdGenHiddenColumnsEmpty/ThirdGenHiddenColumnsEmpty'
import ThirdGenHiddenItems from './components/DynamicItems/ThirdGenHiddenItems'
import styles from './ThirdGenColumnDrawer.less'

const SENSOR_DISTANCE = 4

export interface ColumnProps {
  id: ColumnContextMapKeys
  name: string
  hidden: boolean
  locked?: boolean
}

interface DragProps {
  active: Active
  over: Over | null
}

const ThirdGenColumnDrawer: FC<{ data: Columns }> = ({ data }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { currentUser } = useGetUserData()
  const [open, setOpen] = useState(false)
  const [popconfirmVisible, setPopconfirmVisible] = useState(
    data.similarityColumnHidden
  )
  const [displayColumns, setDisplayColumns] = useState<Array<ColumnProps>>(
    data.columns
  )

  const hiddenColumns = displayColumns.filter((column) => column.hidden)
  const draggableColumns = displayColumns.filter((column) => !column.locked)
  const lockedColumns = data.columns.filter((column) => column.locked)

  // Sensors are required to handle the up/down on click listeners, without this, they do not work.
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: SENSOR_DISTANCE,
      },
    })
  )

  const updateUiState = useUpdateUiState(currentUser)

  const hasChanges = !isEqual(data?.columns, displayColumns)

  const handleToggle = (): void => {
    setOpen(!open)
    setDisplayColumns(data.columns)
  }

  const handleOnCancel = (): void => {
    if (!hasChanges) {
      handleToggle()
    }
  }

  const handleApplyChanges = ({
    resetToDefault = false,
  }: {
    resetToDefault?: boolean
  } = {}): void => {
    const hiddenColumnNames = hiddenColumns.map((column) => column.id)
    const displayColumnNames = displayColumns.map((column) => column.id)
    const lockedColumnNames = lockedColumns.map((column) => column.id)

    updateUiState.mutate(
      {
        powersearch: resetToDefault
          ? {}
          : {
              hiddenColumns: hiddenColumnNames,
              columnOrdering: Array.from(
                new Set(lockedColumnNames.concat(displayColumnNames))
              ),
            },
      },
      {
        onSettled: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['searchContext'],
          })
          setOpen(!open)
        },
      }
    )
  }

  const resetToDefault = (): void => {
    handleApplyChanges({ resetToDefault: true })
  }

  const hideColumn = (column: ColumnProps): void => {
    const { id } = column

    const columnPosition = displayColumns.findIndex(
      (column) => column.id === id
    )

    const newColumns = displayColumns.with(columnPosition, {
      ...column,
      hidden: true,
    })

    setDisplayColumns(newColumns)
  }

  const showColumn = (column: ColumnProps): void => {
    const { id } = column

    const columnPosition = displayColumns.findIndex(
      (column) => column.id === id
    )

    const removedColumns = displayColumns.toSpliced(columnPosition, 1)

    const newColumns = [...removedColumns, { ...column, hidden: false }]

    setDisplayColumns(newColumns)
  }

  const handleDrag = ({ active, over }: DragProps): void => {
    if (over && active.id !== over?.id) {
      const activeIndex = displayColumns.findIndex(({ id }) => id === active.id)
      const overIndex = displayColumns.findIndex(({ id }) => id === over.id)

      setDisplayColumns(arrayMove(displayColumns, activeIndex, overIndex))
    }
  }

  const drawerButtonText = hiddenColumns.length
    ? t('columnDrawer.hiddenColumnsButton', { count: hiddenColumns.length })
    : t('columnDrawer.button')

  return (
    <>
      <Popconfirm
        overlayClassName="sig-popconfirm"
        icon={null}
        open={popconfirmVisible}
        title={
          <div data-test-id="hiddenColumnsPopoverTitle">
            <TitleThirdGen level={4}>
              {t('columnDrawer.hiddenColumnsPopconfirm.title')}
            </TitleThirdGen>
            <TextThirdGen>
              {t('columnDrawer.hiddenColumnsPopconfirm.subtext')}
            </TextThirdGen>
          </div>
        }
        onConfirm={() => {
          setOpen(true)
          setDisplayColumns(data.columns)
          setPopconfirmVisible(false)
        }}
        onCancel={() => {
          setOpen(false)
          setPopconfirmVisible(false)
        }}
        okText={t('columnDrawer.seeColumns')}
        cancelText={t('columnDrawer.skip')}
      >
        <Button
          type="link"
          data-analytics-id="column-drawer-button"
          data-test-id="columnDrawerButton"
          onClick={handleToggle}
        >
          <Icon component={TableColumnIcon} />
          {drawerButtonText}
        </Button>
      </Popconfirm>

      <Drawer
        onClose={handleOnCancel}
        data-test-id="columnDrawer"
        open={open}
        width={480}
        closable={false}
        footer={
          <ThirdGenColumnDrawerFooter
            loading={updateUiState.isLoading}
            onCancel={handleOnCancel}
            onApply={handleApplyChanges}
            onClear={handleToggle}
            resetToDefault={resetToDefault}
            hasChanges={hasChanges}
          />
        }
        title={
          <Flex gap={spacingMD} align="center">
            <Popconfirm
              overlayClassName="sig-popconfirm"
              data-test-id="drawerCloseConfirmTop"
              icon={null}
              title={
                <Flex gap={spacingSM} data-test-id="drawerResetConfirm">
                  <CircleExclamation
                    fill={colorGold}
                    className={styles.warningIcon}
                  />
                  <div>
                    <TitleThirdGen level={3}>
                      {t('columnDrawer.confirmChanges.title')}
                    </TitleThirdGen>
                    <Space size="xs" />
                    <TextThirdGen weight="normal" className={styles.subtext}>
                      {t('columnDrawer.confirmChanges.subtext')}
                    </TextThirdGen>
                  </div>
                </Flex>
              }
              onConfirm={handleToggle}
              disabled={!hasChanges || updateUiState.isLoading}
              placement="topRight"
              okText={t('columnDrawer.confirmChanges.cancel')}
              cancelText={t('columnDrawer.confirmChanges.keep')}
              cancelButtonProps={
                {
                  'data-analytics-id': 'keep-drawer-button',
                  size: 'small',
                  type: 'link',
                } as unknown as ButtonProps
              }
              okButtonProps={
                {
                  'data-analytics-id': 'reset-drawer-button',
                  size: 'small',
                  type: 'primary',
                  className: styles.okButton,
                } as unknown as ButtonProps
              }
            >
              <Button
                onClick={handleOnCancel}
                icon={<CloseOutlined fill={colorSlate} />}
                type="text"
              />
            </Popconfirm>
            <TitleThirdGen level={2}>{t('columnDrawer.title')}</TitleThirdGen>
          </Flex>
        }
      >
        <TitleThirdGen level={3}>
          {t('columnDrawer.visibleColumns')}
        </TitleThirdGen>
        <Space size="sm" />
        <ThirdGenLockedItems lockedColumns={lockedColumns} />
        <Space size="md" />
        <DndContext
          sensors={sensors}
          modifiers={[restrictToFirstScrollableAncestor]}
          onDragEnd={({ active, over }) => handleDrag({ active, over })}
        >
          <ThirdGenDynamicItems
            loading={updateUiState.isLoading}
            draggableColumns={draggableColumns}
            setDisplayColumns={setDisplayColumns}
            hideColumn={hideColumn}
          />
        </DndContext>
        <Space size="xxl" />
        <TitleThirdGen level={3}>
          {t('columnDrawer.hiddenColumns')}
        </TitleThirdGen>
        <Space size="sm" />

        {hiddenColumns.length > 0 && (
          <ThirdGenHiddenItems
            loading={updateUiState.isLoading}
            displayColumns={displayColumns}
            showColumn={showColumn}
          />
        )}

        {!hiddenColumns.length && <ThirdGenHiddenColumnsEmpty />}
      </Drawer>
    </>
  )
}

export default ThirdGenColumnDrawer
