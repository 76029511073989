import { FC } from 'react'
import { NavigationBar } from '@signifyd/components'
import { UserData } from 'core/hooks/useGetUserData'
import useUpdateUiState from '../NavigationWrapper/useUpdateUiState'

interface Props {
  userData: UserData
}

const ThirdGenNavBar: FC<Props> = ({ userData }) => {
  const { currentUser, userConfigs } = userData
  const updateUiState = useUpdateUiState(currentUser)

  return (
    <NavigationBar
      user={currentUser}
      userConfigs={userConfigs}
      onLanguageSelectionChange={async (selectedLocale) => {
        await updateUiState.mutateAsync({ locale: selectedLocale })
      }}
      onUpdateUserState={(collapsed) => {
        updateUiState.mutate({ navBarCollapsed: collapsed })
      }}
    />
  )
}

export default ThirdGenNavBar
