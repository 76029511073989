import {
  FeatureFlagController,
  PageSpinnerThirdGen,
  thirdGenTreatmentKey,
  useUserContext,
  TestFeatureFlag,
} from '@signifyd/components'
import { SECONDARY_MATCH_KEY } from 'pages/InvestigationSearchPage/hooks/useHasSecondaryMatches/useHasSecondaryMatches'
import { FC } from 'react'

export const featureFlagConfig = [
  { key: SECONDARY_MATCH_KEY, label: 'Secondary Matches' },
  { key: thirdGenTreatmentKey, label: 'Third Gen' },
]

interface Props {
  testFeatureFlags?: TestFeatureFlag
}

const CaseSearchFeatureFlagController: FC<Props> = ({
  testFeatureFlags,
  children,
}) => {
  const { user } = useUserContext()

  return (
    <FeatureFlagController
      user={user}
      LoadingComponent={<PageSpinnerThirdGen />}
      featureFlagConfig={featureFlagConfig}
      testFeatureFlags={testFeatureFlags}
    >
      {children}
    </FeatureFlagController>
  )
}

export default CaseSearchFeatureFlagController
