import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'antd'
import {
  EyeSlash,
  Space,
  TextThirdGen,
  TitleThirdGen,
} from '@signifyd/components'
import { colorCerulean } from '@signifyd/colors'
import styles from './ThirdGenHiddenColumnsEmpty.less'

const ThirdGenHiddenColumnsEmpty: FC = () => {
  const { t } = useTranslation()

  return (
    <Flex align="center" vertical className={styles.hiddenEmpty}>
      <EyeSlash className={styles.emptyIcon} fill={colorCerulean} />
      <Space size="md" />
      <TitleThirdGen level={3}>{t('columnDrawer.empty.header')}</TitleThirdGen>
      <TextThirdGen paragraph className={styles.text}>
        {t('columnDrawer.empty.value')}
      </TextThirdGen>
    </Flex>
  )
}

export default ThirdGenHiddenColumnsEmpty
