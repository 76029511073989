import { FilterIcon, useIsThirdGen } from '@signifyd/components'
import { FC } from 'react'
import cx from 'classnames'
import {
  colorCerulean,
  colorSlate,
  colorYonder,
  colorCeruleanLight40,
  colorMarengo,
} from '@signifyd/colors'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import styles from './FilterButton.less'

export interface FilterButtonProps {
  selected: boolean
  className?: string
  onClick: () => void
  columnKey: string
}

const FilterButton: FC<FilterButtonProps> = ({
  selected,
  className,
  onClick,
  columnKey,
}) => {
  const { selectedRowKeys } = useTableContext()
  const isThirdGen = useIsThirdGen()
  const isDisabled = !!selectedRowKeys.length

  let filterColor = isThirdGen ? colorMarengo : colorSlate

  if (selected) {
    filterColor = colorCerulean
  }

  if (isDisabled) {
    filterColor = colorYonder
  }

  if (selected && isDisabled) {
    filterColor = colorCeruleanLight40
  }

  const classnames = cx([
    styles.filterIcon,
    selected && styles.filterIconSelected,
    className,
    isDisabled && styles.disabled,
  ])

  const handleClick = (): void => {
    if (!isDisabled) {
      onClick()
    }
  }

  return (
    <div
      data-analytics-id={`filterButton-${columnKey}`}
      data-test-id={`filterButton-${columnKey}`}
      className={classnames}
      onClick={handleClick}
    >
      <FilterIcon fill={filterColor} />
    </div>
  )
}

export default FilterButton
