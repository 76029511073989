import { FC } from 'react'
import { Flex, Pagination } from 'antd'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import useGetInvestigations from 'pages/InvestigationSearchPage/containers/SearchResultsTable/useGetInvestigations'
import { useTablePagination } from 'pages/InvestigationSearchPage/queries/useTablePagination'
import { spacingMD } from '@signifyd/ant'
import useHasSecondaryMatches from 'pages/InvestigationSearchPage/hooks/useHasSecondaryMatches/useHasSecondaryMatches'
import SimilarityMatchTypes from '../SimilarityMatchTypes'

const SearchPagination: FC = () => {
  const {
    query: { current, pageSize },
    setPagination,
  } = useTablePagination()
  const hasSecondaryMatches = useHasSecondaryMatches()
  const { saveCurrentColumnPosition, selectedRowKeys } = useTableContext()

  const { isLoading, data } = useGetInvestigations()

  const total = data?.totalResults ?? 0

  const handlePaginationChange = (pageId: number): void => {
    setPagination({ current: pageId })
    saveCurrentColumnPosition()
  }

  if (!current || !pageSize || !total) {
    return null
  }

  return (
    <Flex
      align="center"
      justify="space-between"
      data-test-id="searchPagination"
      gap={spacingMD}
    >
      <Pagination
        current={current}
        pageSize={pageSize}
        total={total}
        onChange={handlePaginationChange}
        showSizeChanger
        onShowSizeChange={(newPage, newPageSize) => {
          setPagination({ current: newPage, pageSize: newPageSize })
        }}
        pageSizeOptions={['25', '50', '75', '200']}
        disabled={isLoading || !!selectedRowKeys.length}
      />
      {hasSecondaryMatches && <SimilarityMatchTypes />}
    </Flex>
  )
}

export default SearchPagination
