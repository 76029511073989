import { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import {
  ErrorBoundary,
  PageSpinnerThirdGen,
  ProdWarningModal,
  UserProvider,
  defaultQueryClientConfig,
  initLocalization,
} from '@signifyd/components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import locales from 'locales'
import AppContainer from 'core/components/AppContainer'
import CaseSearchFeatureFlagController from 'core/components/AppContainer/CaseSearchFeatureFlagController'

initLocalization(locales)

const queryClient = new QueryClient(defaultQueryClientConfig)

const App: FC = () => (
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <UserProvider LoadingComponent={<PageSpinnerThirdGen />}>
            <CaseSearchFeatureFlagController>
              <AppContainer />
            </CaseSearchFeatureFlagController>
          </UserProvider>
          <ProdWarningModal />
          <ReactQueryDevtools initialIsOpen={false} />
        </ErrorBoundary>
      </QueryClientProvider>
    </QueryParamProvider>
  </BrowserRouter>
)

export default App
