import { FC } from 'react'
import { Space, useIsThirdGen } from '@signifyd/components'
import { Col, Row } from 'antd'
import SearchPagination from 'pages/InvestigationSearchPage/components/SearchPagination'
import TableContainer from 'pages/InvestigationSearchPage/containers/SearchResultsTable'
import DownloadButton from 'pages/InvestigationSearchPage/components/DownloadButton'
import SecondGenColumnDrawer from 'pages/InvestigationSearchPage/components/ColumnDrawer'
import ThirdGenColumnDrawer from 'pages/InvestigationSearchPage/components/ColumnDrawer/thirdGen/ThirdGenColumnDrawer'
import useGetSavedColumns from '../../components/ColumnDrawer/useGetSavedColumns'
import styles from './SearchContainer.less'

const SearchContainer: FC = () => {
  const data = useGetSavedColumns()
  const isThirdGen = useIsThirdGen()

  const ColumnDrawer = isThirdGen ? ThirdGenColumnDrawer : SecondGenColumnDrawer

  return (
    <div className={styles.container} data-test-id="searchContainer">
      <>
        <Space size="md" />
        <Row justify="space-between">
          <Col>
            <SearchPagination />
          </Col>
          <Col>
            {data.columns.length > 0 && <ColumnDrawer data={data} />}
            <DownloadButton />
          </Col>
        </Row>

        <Space size="md" />
        <div className={styles.content}>
          <TableContainer />
        </div>
      </>
    </div>
  )
}

export default SearchContainer
