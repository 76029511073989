export default {
  search: 'Power Search',
  disabledSearch:
    'Filtering and other actions are unavailable whilst multiple orders are selected',
  resetSearch: 'Reset search and filters',
  listInvestigations: {
    error: 'There was a problem with your search. Please try again.',
  },
  investigationError:
    'There was a problem retrieving the investigation. Please try again.',
  searchResults: {
    noCases: '0-0 of 0 results',
    oneCase: '1-1 of 1 results from',
    multipleCases: '{{min}}-{{max}} of {{total}} results from',
  },
  column: {
    caseId: 'Case ID',
    date: 'Date',
    guaranteeDisposition: 'Guarantee disposition',
    orderTotal: 'Order total',
    isTest: 'Case is test',
    orderId: 'Order ID',
    transactionId: 'Transaction ID',
    cardLastFourDigits: 'Last 4',
    bindbBank: 'Bank name',
    userAccountEmail: 'Account email',
    confirmationEmail: 'Confirmation email',
    billingAddress: 'Billing address',
    deliveryAddress: 'Delivery address',
    addressMatch: 'Address match?',
    userAccountPhone: 'Account phone',
    confirmationPhone: 'Confirmation phone',
    team: 'Team',
    teamId: 'Team ID',
    customer: 'Customer',
    orderReviewFlag: 'Order review flag',
    products: 'Items',
    chargeback: 'Chargeback',
    bin: 'BIN',
    deviceId: 'Device ID',
    deviceIdTrimmed: '...{{value}}',
    ipAddress: 'IP address',
    ipGeolocation: 'IP geolocation',
    avs: 'AVS',
    cvv: 'CVV',
    binCountry: 'BIN country',
    binCountryCode: 'BIN country code',
    shippingMethod: 'Shipping method',
    sellerId: 'Seller ID',
    parentEntity: 'Parent entity',
    sellerName: 'Seller name',
    score: 'Score',
    channel: 'Channel',
    userAccountNumber: 'Account number',
    paymentGateway: 'Payment gateway',
    paymentMethod: 'Payment method',
    authorizationStatus: 'Auth status',
    authorizationFailureReason: 'Auth fail reason',
    guaranteeAutoDecisionReason: 'Guarantee auto decision reason',
    guaranteeAutoDecisionReasonDescription:
      'Guarantee auto decision reason description',
    guaranteeReviewTimeoutDisposition: 'Timeout disposition',
    recommendedDecisionDisposition: 'Recommendation',
    billingAddressProvinceCode: 'Province code',
    checkoutPolicyName: 'Checkout policy name',
    checkoutPolicyAction: 'Checkout policy action',
    discountCode: 'Discount code',
    returnPolicyAction: 'Return policy action',
    returnPolicyName: 'Return policy name',
    cardHolderName: 'Cardholder name',
    deliveryRecipient: 'Delivery recipient',
    accountHolderTaxId: 'Card holder tax ID',
    normalizedChargebackReason: 'Chargeback type',
    policyOutcome: 'Policy outcome',
    addressDistance: 'Address distance',
    orderItemCount: 'Order item count',
    bank: 'Bank',
    proxyScore: 'Proxy score',
    prioScore: 'Prio score',
    ipAddressDistance: 'IP address distance',
    shippingAmount: 'Shipping amount',
    processingPolicy: 'Processing policy',
    recordLocator: 'Record locator',
    leadPassenger: 'Lead passenger',
  },
  truncatedTextView: {
    view: 'View',
    hide: 'Hide',
  },
  spinnerText: 'Just a moment! Loading orders.',
  searchTypeSwitch: {
    and: 'Match all filters',
    or: 'Match any filters',
    notification: {
      success: {
        and: 'Orders now updated to show orders that match all filter terms',
        or: 'Orders now updated to show orders that match any filter terms',
      },
      failure: {
        and: 'Apologies — we are currently unable to update your filter criteria to match all. Refresh and try again.',
        or: 'Apologies — we are currently unable to update your filter criteria to match any. Refresh and try again.',
      },
    },
  },
  downloadButton: {
    text: 'Download',
    unavailable: 'Download started',
    failure: 'Apologies — order download failed, try again',
  },
  searchFailure: 'Apologies — verify search and try again',
  more: '{{count}} more',
  products: {
    fallback: 'Item name is empty',
  },
  policyTag: {
    noMatch: 'No match',
  },
  limitedResults: {
    notificationAdmin: {
      message: 'Limited results',
      description:
        'Too many results to show. To display results, we recommend refining the search further.',
    },
    notification: {
      message: 'Limited results',
      description:
        'Too many orders to display. To view all available orders, use the download button below.',
    },
  },
}
