import { FC, ReactNode } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from 'antd/lib/button'
import { ColumnProps } from 'antd/lib/table'
import { PredictionList } from '@signifyd/http'
import { Key } from 'antd/lib/table/interface'
import {
  EvaluatedInvestigation,
  InvestigationEvaluation,
} from 'pages/InvestigationSearchPage/hooks/useGetEvaluatedInvestigations'
import { useIsThirdGen } from '@signifyd/components'
import BulkActionsConfirmationModalContent from './BulkActionsConfirmationModalContent'
import { MODAL_WIDTH } from '../constants'
import { useBulkActionsContext } from '../BulkActionsProvider'
import { DataSource } from '../types'

interface Props {
  evaluation?: InvestigationEvaluation
  isLoading?: boolean
  isVisible: boolean
  selectedRows: Array<Key>
  selectedList?: PredictionList
  dataSource?: DataSource
  setSelectedRows: (rows: Array<Key>) => void
  onSubmit: () => Promise<void> | (() => void)
  onPrevious: () => void
  modalDescription: ReactNode
  getColumns: () => Array<ColumnProps<EvaluatedInvestigation>>
}

const BulkActionsConfirmationModal: FC<Props> = ({
  evaluation,
  isLoading,
  onSubmit,
  onPrevious,
  selectedRows,
  setSelectedRows,
  isVisible,
  getColumns,
  modalDescription,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  const { bulkActionType, resetAddToList } = useBulkActionsContext()

  const okButtonProps = {
    'data-analytics-id': 'bulk-actions-confirm-ok',
    'data-test-id': 'bulkActionsConfirmContinueButton',
    disabled: selectedRows.length < 1,
  }

  const cancelButtonProps = {
    'data-analytics-id': 'bulk-actions-confirm-cancel',
    type: 'link',
    onClick: onPrevious,
  } as unknown as ButtonProps

  if (!bulkActionType) {
    return null
  }

  return (
    <Modal
      data-test-id="bulkActionsConfirmationModal"
      title={t('bulkActions.confirmationModal.modalTitle')}
      open={isVisible}
      confirmLoading={isLoading}
      width={MODAL_WIDTH}
      okButtonProps={okButtonProps}
      okText={t('bulkActions.confirmationModal.okText')}
      okType={isThirdGen ? 'primary' : 'link'}
      onOk={onSubmit}
      cancelButtonProps={cancelButtonProps}
      cancelText={t('bulkActions.confirmationModal.cancelText')}
      onCancel={resetAddToList}
      destroyOnClose
      closable
      maskClosable={false}
    >
      <BulkActionsConfirmationModalContent
        selectedRowKeys={selectedRows}
        setSelectedRowKeys={setSelectedRows}
        evaluation={evaluation}
        isLoading={isLoading}
        description={modalDescription}
        getColumns={getColumns}
      />
    </Modal>
  )
}

export default BulkActionsConfirmationModal
