import { DefaultColumns } from 'core/constants'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Padlock, TextThirdGen } from '@signifyd/components'
import { Flex, Tooltip } from 'antd'
import { colorMarengo } from '@signifyd/colors'
import { spacingMD } from '@signifyd/ant'
import cx from 'classnames'
import { ColumnProps } from '../../ThirdGenColumnDrawer'
import styles from './ThirdGenLockedItems.less'

interface Props {
  lockedColumns: Array<ColumnProps>
}

const ThirdGenLockedItems: FC<Props> = ({ lockedColumns }) => {
  const { t } = useTranslation()

  return (
    <Flex gap={spacingMD} vertical>
      {lockedColumns.map(({ id, name }) => {
        const columnName = name as DefaultColumns

        return (
          <Tooltip
            placement="top"
            arrow={{ pointAtCenter: true }}
            title={<div>PUT SOMETHIGN HERE?</div>}
            key={id}
          >
            <Flex
              align="center"
              gap={spacingMD}
              className={cx([styles.lockedContainer, styles.itemContainer])}
              data-test-id="columnLocked"
            >
              <Padlock
                data-test-id={`columnLocked-${id}`}
                className={styles.lockIcon}
                fill={colorMarengo}
              />
              <TextThirdGen className={styles.lockedItem} paragraph>
                {t(`columnDrawer.defaultColumns.${columnName}`)}
              </TextThirdGen>
            </Flex>
          </Tooltip>
        )
      })}
    </Flex>
  )
}

export default ThirdGenLockedItems
