import { INV_SIMILARITY_FIELD } from '@signifyd/http'

const similarityColumnNameMap = {
  [INV_SIMILARITY_FIELD.BILLING_ADDRESS]: 'billingAddress',
  [INV_SIMILARITY_FIELD.CONFIRMATION_EMAIL]: 'recipientConfirmationEmail',
  [INV_SIMILARITY_FIELD.CONFIRMATION_PHONE]: 'recipientConfirmationPhone',
  [INV_SIMILARITY_FIELD.DELIVERY_ADDRESS]: 'recipientAddress',
  [INV_SIMILARITY_FIELD.DEVICE_ID]: 'deviceId',
  [INV_SIMILARITY_FIELD.IP_ADDRESS]: 'browserIp',
  [INV_SIMILARITY_FIELD.ACCOUNT_PHONE]: 'userAccountPhone',
  [INV_SIMILARITY_FIELD.ACCOUNT_EMAIL]: 'userAccountEmail',
  [INV_SIMILARITY_FIELD.DELIVERY_RECIPIENT]: 'deliveryRecipient',
  [INV_SIMILARITY_FIELD.CARDHOLDER_NAME]: 'cardHolderName',
}

export const checkSimilarityColumns = (
  hiddenColumns: Array<string>,
  similarityAttributes?: Array<INV_SIMILARITY_FIELD>
): boolean => {
  if (!similarityAttributes?.length || !hiddenColumns.length) {
    return false
  }

  const mappedSimilarityAttribute = similarityAttributes.map(
    (similarityAttribute) => similarityColumnNameMap[similarityAttribute]
  )

  return hiddenColumns.some((column) =>
    mappedSimilarityAttribute.includes(column)
  )
}
