import { FC, Fragment } from 'react'
import { Flex } from 'antd'
import { spacingMD } from '@signifyd/ant'
import { ColumnProps } from '../../ThirdGenColumnDrawer'
import ItemLayout from './ItemLayout'

interface Props {
  displayColumns: Array<ColumnProps>
  showColumn: (column: ColumnProps) => void
  loading: boolean
}

const ThirdGenHiddenItems: FC<Props> = ({
  displayColumns,
  showColumn,
  loading,
}) => {
  const hiddenColumns = displayColumns.filter((column) => column.hidden)

  return (
    <Flex gap={spacingMD} vertical data-test-id="hiddenColumns">
      {hiddenColumns.map((column) => (
        <Fragment key={column.id}>
          <ItemLayout
            key={column.id}
            loading={loading}
            column={column}
            moveUpDisabled={true}
            moveDownDisabled={true}
            toggleColumn={showColumn}
          />
        </Fragment>
      ))}
    </Flex>
  )
}

export default ThirdGenHiddenItems
