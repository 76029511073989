import { useState, FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Input, message } from 'antd'
import cx from 'classnames'
import { useTableQueryParams } from 'pages/InvestigationSearchPage/queries/useTableQueryParams'
import { useTablePagination } from 'pages/InvestigationSearchPage/queries/useTablePagination'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import styles from './SearchBar.less'
import SearchHelpHint from '../SearchHelpHint'

interface SearchTermsEvent {
  target: { value: string }
}

const SearchBar: FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const {
    query: { general },
    setQuery,
  } = useTableQueryParams()
  const { setPagination } = useTablePagination()
  const { selectedRowKeys } = useTableContext()

  const [searchValue, setSearchValue] = useState(general ?? '')

  useEffect(() => {
    // if reset button is triggered outside of the component, it needs to clear the search value
    setSearchValue(general ?? '')
  }, [general])

  const handleSearchInputChange = (e: SearchTermsEvent): void => {
    setSearchValue(e.target.value)
  }

  const handleSubmit = (): void => {
    const isSearchPage = /^\/casesearch/.test(location.pathname)
    if (isSearchPage) {
      const delimiter = searchValue.includes(' ') ? ' ' : ','
      const splitSearch = searchValue.split(delimiter)

      const ELASTIC_SEARCH_TERM_CAP = 1000

      if (splitSearch.length >= ELASTIC_SEARCH_TERM_CAP) {
        message.error(t('searchBar.searchTermsExceededError'))

        return
      }

      setQuery({
        general: searchValue || undefined,
      })
      setPagination({ current: 1 })
    } else {
      const params = new URLSearchParams({
        offset: '0',
        limit: '50',
        status: 'ALL',
      })

      if (searchValue) {
        params.set('general', searchValue)
      }

      navigate(`/casesearch?${params.toString()}`)
    }
  }

  const onClearSearchInput = (): void => {
    setSearchValue('')

    setQuery({
      general: undefined,
    })
  }

  return (
    <Card
      size="small"
      className={cx(!!selectedRowKeys.length && styles.disabled)}
    >
      <Flex align="center" className={styles.search}>
        <SearchOutlined />
        <Input
          autoComplete="off"
          className={styles.input}
          type="text"
          placeholder={t('searchBar.placeholder')}
          value={searchValue}
          onChange={handleSearchInputChange}
          onPressEnter={handleSubmit}
          autoFocus
          data-test-id="searchBarInput"
          data-analytics-id="search-bar-input"
          disabled={!!selectedRowKeys.length}
        />
        {!selectedRowKeys.length && searchValue.length > 0 && (
          <Button
            type="link"
            onClick={onClearSearchInput}
            data-test-id="searchBarResetButton"
            data-analytics-id="search-bar-reset-button"
          >
            {t('searchBar.resetSearch')}
            <CloseOutlined />
          </Button>
        )}
        <SearchHelpHint />
      </Flex>
    </Card>
  )
}

export default SearchBar
