import { EnhancedInvInfo, AUTO_DECISION_REVIEWER_IDS } from 'core/constants'
import { InvestigationInfo, INV_GUARANTEE_DISPOSITION } from '@signifyd/http'

export const createEnhancedInvestigations = (
  investigations: Array<InvestigationInfo>,
  isTraining = false
): Array<EnhancedInvInfo> => {
  return investigations.map((investigation) => {
    const { guaranteeDisposition, guaranteeReviewedBy, claims, chargebacks } =
      investigation

    const hasChargeback =
      !!claims?.[0]?.chargebacks?.length || !!chargebacks?.length

    const guaranteeReviewed =
      guaranteeDisposition === INV_GUARANTEE_DISPOSITION.APPROVED ||
      guaranteeDisposition === INV_GUARANTEE_DISPOSITION.DECLINED

    const isDecidedByMR =
      guaranteeReviewed && !AUTO_DECISION_REVIEWER_IDS.has(guaranteeReviewedBy)

    const maskInvestigationsForTraining = !isTraining
      ? investigation
      : {
          guaranteeDisposition: null,
          hasChargeback: false,
          investigationReviewDisposition: undefined,
        }

    return {
      ...investigation,
      hasChargeback,
      isDecidedByMR,
      ...maskInvestigationsForTraining,
    }
  })
}
