export default {
  guaranteeDisposition: {
    APPROVED: 'Approved',
    CANCELED: 'Canceled',
    CLOSED: 'Closed',
    DECLINED: 'Declined',
    IN_REVIEW: 'In review',
    PENDING: 'Pending',
    PROCESSING: 'Processing',
    UNREQUESTED: 'Unrequested',
    DECLINED_AUTO: 'Machine declined',
    DECLINED_MR: 'Manual review declined',
    APPROVED_AUTO: 'Machine approved',
    APPROVED_MR: 'Manual review approved',
    ESCALATED: 'Escalated',
    PROCESSING_REGUARANTEE: 'Processing re-guarantee',
    UNDEFINED_DISPOSITION: 'Undefined disposition',
  },
  orderChannel: {
    IN_STORE_KIOSK: 'In store kiosk',
    MARKETPLACE: 'Marketplace',
    MOBILE_APP: 'Mobile app',
    OFFLINE: 'Offline',
    PHONE: 'Phone',
    POS: 'POS',
    SCAN_AND_GO: 'Scan & Go',
    SOCIAL: 'Social',
    WEB: 'Web',
  },
  orderReviewFlag: {
    GOOD: 'Good',
    FRAUDULENT: 'Bad',
    UNSET: 'Unset',
  },
  isTestInvestigation: {
    true: 'Case is test',
  },
  addressMatch: 'Billing and delivery address match',
}
