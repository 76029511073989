import { FC } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from 'antd/lib/button'
import { useIsThirdGen } from '@signifyd/components'
import { MODAL_WIDTH } from '../constants'
import { useBulkActionsContext } from '../BulkActionsProvider'

export const MAX_NOTE_LENGTH = 1000

interface Props {
  onNext: () => void
  onPrevious: () => void
  isVisible: boolean
  cancelText?: string
}

const BulkActionsNotesModal: FC<Props> = ({
  onNext,
  onPrevious,
  children,
  isVisible,
  cancelText,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  const { note, resetAddToList } = useBulkActionsContext()

  const okButtonProps = {
    'data-analytics-id': 'bulk-actions-note-ok',
    'data-test-id': 'bulkActionNotesContinueButton',
    disabled: note.trim().length < 1 || note.trim().length > MAX_NOTE_LENGTH,
  }

  const cancelButtonProps = {
    'data-analytics-id': 'bulk-actions-note-cancel',
    type: 'link',
    onClick: onPrevious,
  } as unknown as ButtonProps

  return (
    <Modal
      width={MODAL_WIDTH}
      data-test-id="bulkActionsNotesModal"
      title={t('bulkActions.notesModal.modalTitle')}
      open={isVisible}
      okButtonProps={okButtonProps}
      okText={t('bulkActions.notesModal.okText')}
      cancelText={cancelText}
      okType={isThirdGen ? 'primary' : 'link'}
      onOk={onNext}
      cancelButtonProps={cancelButtonProps}
      onCancel={resetAddToList}
      destroyOnClose
      closable
      maskClosable={false}
    >
      {children}
    </Modal>
  )
}

export default BulkActionsNotesModal
