import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  FlexibleSearchRequest,
  getInvestigationSearch,
  InvestigationInfo,
  SEARCH_FIELD,
  SEARCH_OPERATOR,
  INV_SEARCH_VIEW,
} from '@signifyd/http'
import { message } from 'antd'
import { i18nInstance } from '@signifyd/components'

const GET_INVESTIGATION_QUERY_KEY = 'getInvestigation'

const useGetInvestigation = (
  investigationId?: number | null
): UseQueryResult<InvestigationInfo> =>
  useQuery({
    queryKey: [GET_INVESTIGATION_QUERY_KEY, investigationId],
    queryFn: async () => {
      const searchRequest: FlexibleSearchRequest<INV_SEARCH_VIEW.INVESTIGATION> =
        {
          query: {
            field: {
              fieldName: SEARCH_FIELD.investigationId,
              operator: SEARCH_OPERATOR.EQUAL,
              values: investigationId!,
            },
          },
        }

      try {
        const { data } = await getInvestigationSearch(searchRequest)

        return data.investigations[0]
      } catch {
        message.error(i18nInstance.t('investigationSearch.investigationError'))

        return null
      }
    },

    enabled: !!investigationId,
  })

export default useGetInvestigation
