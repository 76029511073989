export default {
  BILLING_ADDRESS: 'Billing address',
  DELIVERY_ADDRESS: 'Delivery address',
  CONFIRMATION_EMAIL: 'Confirmation email',
  CONFIRMATION_PHONE: 'Confirmation phone',
  DEVICE_ID: 'Device ID',
  IP_ADDRESS: 'IP address',
  SIM: 'SIM',
  matchTag: {
    direct: 'Direct',
    indirect: 'Indirect',
    tooltip: {
      direct: 'Order data that matches directly to your original order',
      indirect:
        'Order data that has appeared more than once among the 10,000 most recent investigations, but does not match exactly to the original order',
    },
  },
}
