import useGetUserData from 'core/hooks/useGetUserData'
import useGetInvestigationSearchContext from 'core/hooks/useGetInvestigationSearchContext'
import { useTeamCustomerIdQueryParams } from 'pages/InvestigationSearchPage/queries/useTeamCustomerIdQueryParams'
import { useIsThirdGen } from '@signifyd/components'
import useHasSecondaryMatches from 'pages/InvestigationSearchPage/hooks/useHasSecondaryMatches/useHasSecondaryMatches'
import useSimilarityParams from 'core/hooks/useSimilarityParams'
import getThirdGenColumns, {
  SearchTableColumnProps,
} from './ColumnThirdGenConfig'
import getColumns from './ColumnConfig'

const useGetColumns = (): {
  columns: Array<SearchTableColumnProps>
  isFetching: boolean
} => {
  const isThirdGen = useIsThirdGen()
  const hasSecondaryMatches = useHasSecondaryMatches()
  const params = useTeamCustomerIdQueryParams()
  const userData = useGetUserData()
  const { data: userColumns, isFetching } =
    useGetInvestigationSearchContext(params)
  const similarityParams = useSimilarityParams()

  if (!userData || !userColumns || isFetching) {
    return {
      columns: [],
      isFetching,
    }
  }

  const firstSimilarity = hasSecondaryMatches
    ? undefined
    : similarityParams?.similarityAttributes[0]

  const { isTraining, isAdmin, currentUser } = userData

  const columns = isThirdGen
    ? getThirdGenColumns({
        isTraining,
        isCustomer: !isAdmin,
        firstSimilarity,
        isRulesBuilderUser: !!currentUser?.features.RULES_BUILDER,
      })
    : getColumns({
        isTraining,
        isCustomer: !isAdmin,
        firstSimilarity,
        isRulesBuilderUser: !!currentUser?.features.RULES_BUILDER,
      })

  const availableTableColumns: Array<SearchTableColumnProps> = []

  userColumns.availableFields.forEach((availableField) => {
    const userAvailableColumn = columns.find(
      (column) => column.contextMatches === availableField
    )

    if (userAvailableColumn) {
      availableTableColumns.push(userAvailableColumn)
    }
  })

  return {
    columns: availableTableColumns,
    isFetching,
  }
}

export default useGetColumns
