import useSimilarityParams from 'core/hooks/useSimilarityParams'
import { DEFAULT_COLUMNS } from 'core/constants'
import useGetUserData from 'core/hooks/useGetUserData'
import { checkSimilarityColumns } from './similarityColumns.utils'
import { ColumnProps } from './ColumnDrawer'
import useGetColumns from '../../containers/SearchResultsTable/useGetColumns'
import {
  COLUMN_CONTEXT_MAP,
  ColumnContextMapKeys,
} from '../../containers/SearchResultsTable/ColumnConfig.utils'

export interface Columns {
  columns: Array<ColumnProps>
  similarityColumnHidden: boolean
}

const isContextMapKey = (key: string): key is ColumnContextMapKeys => {
  return key in COLUMN_CONTEXT_MAP
}

const useGetSavedColumns = (): Columns => {
  const similarity = useSimilarityParams()
  const { currentUser } = useGetUserData()
  const { columns: searchTableColumns, isFetching } = useGetColumns()

  const hiddenColumns = currentUser?.uiState?.powersearch?.hiddenColumns ?? []

  const visibleCols = searchTableColumns.map(
    ({ columnName, contextMatches }) => ({
      id: contextMatches,
      name: columnName,
      hidden: false,
      locked: DEFAULT_COLUMNS.some((defaultCol) => defaultCol === columnName),
    })
  )

  const hiddenCols = hiddenColumns
    .filter(isContextMapKey)
    .map((contextMatches) => ({
      id: contextMatches,
      name: COLUMN_CONTEXT_MAP[contextMatches],
      hidden: true,
    }))

  if (isFetching) {
    return {
      columns: [],
      similarityColumnHidden: false,
    }
  }

  return {
    columns: [...visibleCols, ...hiddenCols],
    similarityColumnHidden: checkSimilarityColumns(
      hiddenColumns,
      similarity?.similarityAttributes
    ),
  }
}

export default useGetSavedColumns
