import {
  useQueryParams,
  ArrayParam,
  NumberParam,
  DecodedValueMap,
} from 'use-query-params'
import { INV_SIMILARITY_FIELD } from '@signifyd/http'

export type SimilarityParamsResult = DecodedValueMap<typeof similarityParamsMap>

export interface SimilarityParams {
  similarityAttributes: Array<INV_SIMILARITY_FIELD>
  similarityInvestigationId: number
}

export interface SimilarityHook extends SimilarityParams {
  resetSimilarityParams: () => void
}

export const similarityParamsMap = {
  similarityInvestigationId: NumberParam,
  similarityAttributes: ArrayParam,
}

/* Sim params get passed in as SIM_IP_ADDRESS but is used everywhere as IP_ADDRESS */
const formatSimilarityField = (attribute: string): string => {
  return attribute.replace('SIM_', '')
}

const isValidSimilarityAttribute = (
  similarityQueryParam: string
): similarityQueryParam is INV_SIMILARITY_FIELD =>
  formatSimilarityField(similarityQueryParam) in INV_SIMILARITY_FIELD

/** These are params passed by the orders console when a use clicks a link currently in SummaryPanelActions */
const useSimilarityParams = (): SimilarityHook | null => {
  const [query, setQuery] = useQueryParams(similarityParamsMap)
  const { similarityAttributes, similarityInvestigationId } = query

  if (!similarityAttributes?.length || !similarityInvestigationId) {
    return null
  }

  const validAttributes = similarityAttributes.reduce<
    Array<INV_SIMILARITY_FIELD>
  >((acc, similarityAttribute) => {
    if (similarityAttribute === null) {
      return acc
    }

    const formattedAttribute = formatSimilarityField(similarityAttribute)
    if (isValidSimilarityAttribute(formattedAttribute)) {
      acc.push(formattedAttribute)
    }

    return acc
  }, [])

  if (!validAttributes.length) {
    return null
  }

  const resetSimilarityParams = (): void => {
    setQuery({
      similarityAttributes: null,
      similarityInvestigationId: null,
    })
  }

  return {
    similarityAttributes: validAttributes,
    similarityInvestigationId: +similarityInvestigationId,
    resetSimilarityParams,
  }
}

export default useSimilarityParams
